.cta-section {
  padding: 4.8rem 0rem;
  background-color: var(--gray-color);
  position: relative;
}
.cta-section .cta-section__laptop {
  text-align: center;
}
.cta-section .cta-section__laptop figure img {
  margin-top: -9.8rem;
  position: initial;
}
.cta-section .cta-section__content {
  text-align: center;
}

@media (min-width: 992px) {
  .cta-section {
    position: relative;
  }
  .cta-section .cta-section__laptop {
    text-align: left;
  }
  .cta-section .cta-section__laptop figure img {
    margin-top: -9.8rem;
    position: absolute;
  }
}