.cta-section{
    padding: 4.8rem 0rem;
    background-color: var(--gray-color);
    position: relative;
    .cta-section__laptop{
        text-align: center;
        figure{
            img{
                margin-top: -9.8rem;
                position: initial;
            }
        }
    }
    .cta-section__content{
        text-align: center;
    }
}

// Small devices 
@media (min-width: 576px) {

}

// Medium devices 
@media (min-width: 768px) {

}

// Large devices
@media (min-width: 992px) {
    .cta-section{
        position: relative;
        .cta-section__laptop{
            text-align: left;
            figure{
                img{
                    margin-top: -9.8rem;
                    position: absolute;
                }
            }
        }
    }
}

// Extra large devices
@media (min-width: 1200px) {

}

// Extra Extra large devices 
@media (min-width: 1400px) {

}